const additionalPackagesChannels = [
    {
        packageName: "FilmBox",
        id: 4408,
        channels: [
            "360 Tunebox",
            "Docubox (HD)",
            "Fastnfunbox (HD)",
            "Fightbox (HD)",
            "Filmbox Art House",
            "Filmbox Extra (HD)",
            "Filmbox Stars",
            "Filmbox Premium (HD)",
            "Fashionbox",
            "Gametoon (HD)",
            "Erox",
            "Eroxxx (HD)",
        ],
    },
    {
        packageName: "Cinestar Premiere",
        id: 2475,
        channels: [
            "Cinestar Premiere (HD)",
            "Cinestar Premiere 2 (HD)"
        ],
    },
    /*{
        packageName: "Happy Dog",
        id: 5703,
        channels: [
            "Happy Puppy (HD)",
            "Dog TV (HD)",
        ],
    },*/
    /*{
          packageName: "BOX paket",
          id: 2654,
          channels: [
            "Dr. Fit (HD)",
            "Planet EARTH (HD)",
            "Woman (HD)",
            "Bangerz (HD)",
            "Emanuelle (HD)",
            "Fast Boyz (HD)",
            "Lesbian Affair (HD)",
            "Oldtimer (HD)",
            "Taboo (HD)",
          ]
        },
        {
          packageName: "BOX VOD",
          id: 4500,
          channels: []
        },
        */
    {
        packageName: "Paket Pink",
        id: 298,
        channels: [
            "Pink Koncert",
            "Pink’n’Roll",
            "Pink Hits",
            "Pink Extra",
            "Pink Plus",
            "Pink Reality",
            "Pink Serije",
            "Pink Zabava",
            "Pink Film",
            "Pink Folk",
            "Pink World",
        ],
    },
    {
        packageName: "Yugo",
        id: 418,
        channels: [
            "RTL Kockica (HD)",
            "FEN TV",
            "FenFolk TV",
            "Hit TV (HD)",
            "Jugoton TV",
            "Non Stop (HD)",
            "24 Vesti",
            "Vijesti",
            "RTL 2 HR (HD)",
            "RTL Living (HD)",
            "RTL Televizija (HD)",
            "TV Slon",
            "Prva World (HD)",
            "Hayat Folk Box",
            "Folk Plus",
            "RTV Shqiptar",
            "Tring 7",
            "Tring Action (HD)",
            "Tring Shqip",
            "Tring Tring",
            "Tring Vizion+ (HD)",
            "Alfa TV BiH",
            "BHT 1 (HD)",
            "Elta 2",
            "Al Jazeera Balkans (HD)",
            "Elta TV",
            "FTV",
            "Hayat",
            "Hema",
            "NTV IC Kakanj (HD)",
            "OTV Valentino",
            "RTRS",
            "O Kanal",
            "TV Sarajevo (HD)",
            "Vikom TV",
            "BIR TV (HD)",
            "OTV (HD)",
            "Narodna TV (HD)",
            "Mreža TV (HD)",
            "TV Jadran",
            "Z1 televizija (HD)",
            "Zdrava Televizija",
            "Alfa TV MAK",
            "Alsat Macedoniae",
            "Kanal 5",
            "MTV 1",
            "MTV 2",
            "MTV 3",
            "Naša TV",
            "Sonce TV",
            "Telma TV",
            "TV Sitel",
            "RTV Atlas",
            "B92 (HD)",
            "Happy TV",
            "KCN",
            "KCN Music",
            "Prva (HD)",
            "RTS 1 (HD)",
            "RTS 2 (HD)",
            "TV Duga Novi Sad",
            "Prva Max (HD)",
            "Prva Files (HD)",
            "Prva Kick (HD)",
            "Prva Life (HD)",
            "Prva Plus (HD)",
            "Happy Reality",
            "Happy Reality 2",
            "KCN 3",
            "Hayat Stil i život (HD)",
            "Hayat Love box (HD)"
        ],
    },
    {
        packageName: "RUS 5+",
        id: 742,
        channels: [
            "Vremya",
            "Dom Kino",
            "Karousel",
            "Muzika Pervogo",
            "TNT Music",
            "Channel One Russia",
            "Telecafe"
        ],
    },
    {
        packageName: "HUN 5+",
        id: 5882,
        channels: [
            "Duna (HD)",
            "Duna World (HD)",
            "M1 (HD)",
            "M2 (HD)",
            "M5 (HD)"
        ],
    },
    {
        packageName: "AmorX",
        id: 3443,
        channels: [
            "Hot XXL (HD)",
            "Balkan Erotic (HD)",
            "Extreme (HD)",
            "Hot Pleasure (HD)",
            "Milf TV (HD)",
            "Play House (HD)",
            "RED xxx (HD)",
        ],
    },
    {
        packageName: "VenusX",
        id: 70,
        channels: [
            "Dorcel TV XXX (HD)",
            "Dusk",
            "Extasy (4K)",
            "Hustler TV (HD)",
            "Hustler TV",
            "Net XXL",
            "Sexation (HD)",
            "Super One",
            "Vivid Red (HD)",
            "Vivid TV",
        ],
    },
    {
        packageName: "Red Point Premium",
        id: 6025,
        channels: [
            "GLAM (HD)",
            "XXXTazy (HD)",
            "Angels (HD)",
            "BooB (HD)",
            "Capable Hole (HD)",
            "Devils Home (HD)",
            "Foxy Dolls (HD)",
            "MIxxx (HD)",
            "Gasscore (HD)",
            "Purple Pills (HD)",
        ],
    },
    {
        packageName: "Športni paket",
        id: 5770,
        channels: [
            "Šport TV (HD)",
            "Šport TV 2 (HD)",
            "Šport TV 3 (HD)",
            "Arena Sport 1 Premium (HD)",
            "Arena Sport 1 (HD)",
            "Arena Sport 2 (HD)",
            "Arena Sport 3 (HD)",
            "Arena Sport 4 (HD)",
            "Arena Fight (HD)",
            "TV Arena Esport (HD)",
            "Eurosport (HD)",
            "Eurosport 2 (HD)",
        ],
    },
    {
        packageName: "Šport TV",
        id: 5768,
        channels: [
            "Šport TV (HD)",
            "Šport TV 2 (HD)",
            "Šport TV 3 (HD)"
        ],
    },
    {
        packageName: "Arena Sport",
        id: 5769,
        channels: [
            "Arena Fight (HD)",
            "Arena Sport 1 Premium (HD)",
            "Arena Sport 1 (HD)",
            "Arena Sport 2 (HD)",
            "Arena Sport 3 (HD)",
            "Arena Sport 4 (HD)",
            "TV Arena Esport (HD)",
        ],
    },
];

export default additionalPackagesChannels;
